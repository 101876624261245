import React from "react"
import styled from "styled-components"
import { v4 } from "uuid"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { Section, SectionPageTitle, BreadCrumb } from "../components/Section"
import CallNow from "../components/CallNow"
import FadeDrop from "../components/FadeDrop"
import { StaticImage } from "gatsby-plugin-image"
import BreakpointUp from "../components/Media/BreakpointUp"

const BannerBgImage = styled.div`
  width: 20%;
  position: absolute;
  top: calc(50% + 60px);
  right: 0;
  transform: translateY(-50%);
  display: none;
  .gatsby-image-wrapper {
    width: 50%;
    height: 100%;
  }
  ${BreakpointUp.lg`
    display:block;
  `}
`
const FaqPage = ({ data, location }) => {
  const faqData = data.allContentfulFaq
  let mainEntity = []
  faqData.edges.forEach(function(item){
    mainEntity.push({
      "@type": "Question",
      "name": item.node.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": item.node.answer.answer
      }
    })
  })
  const schema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": mainEntity
  }
  return (
    <Layout location={location}>
      <Seo title="Metal Building FAQs | Steel Building Informations - Coast To Coast Carports" description="Have any questions about metal buildings? Here you will find all the FAQs and answers to your steel building related queries." schemaMarkup={schema}/>
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <span>FAQs</span>
        </div>
      </BreadCrumb>
      <Section
        pt="215px"
        pb="90px"
        xpt="120px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left sm"
      >
        <BannerBgImage>
          <StaticImage src="../images/question-mark.png" alt="question-mark" />
        </BannerBgImage>
        <div className="container">
          <SectionPageTitle textAlign="left" maxWidth="840px" ml="0">
            Here Are Some Common Frequently Asked Questions About Coast To Coast
            Carports…
          </SectionPageTitle>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="30px" xpb="30px" bgColor="#fff">
        <div className="container">
          {faqData.edges.map(item => {
            return (
              <FadeDrop
                key={v4()}
                question={item.node.question}
                answer={item.node.answer}
              />
            )
          })}
        </div>
      </Section>
      <CallNow />
    </Layout>
  )
}

export default FaqPage

export const pageQuery = graphql`
  query FaqPageQuery {
    allContentfulFaq(sort: { fields: order, order: ASC }) {
      edges {
        node {
          question
          answer {
            answer
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
