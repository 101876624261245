import React, { Component } from "react"
import styled from "styled-components"
import BreakpointUp from "../Media/BreakpointUp"

const DropWrapp = styled.div`    
  border-bottom:1px solid #DDE4F0;
  position:relative;
  padding: 30px 0;
  margin:30px 0;
  ${BreakpointUp.md`    
    padding: 60px 0;
    margin:0;
  `}
  &:before{    
    content: "Q&A";
    color:rgba(223, 227, 239, 0.4);
    display: flex;
    border: 10px solid rgba(221,228,240,0.5);
    text-align: center;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    font-weight: 900;
    font-size: 28px;
		line-height: 38px;
    ${BreakpointUp.md`    
      position: absolute;
      left: 0;
      top: 50%;
      width: 180px;
      height: 180px;
      border-radius: 50%;
      border-width:30px;
      font-size: 40px;
			line-height: 52px;
      font-weight: 900;
    `} 
  } 

`
const DropButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const DropButtonText = styled.h2`  
  margin-bottom: 0;
  display:block;
  color:#000;  
  font-weight: 900;
  font-size: 22px;
  line-height: 28px;
  ${BreakpointUp.md`    
    padding-left:230px;
    font-size: 32px;
    line-height: 42px;
  `} 
   
`
const FadeContent = styled.div`
  display: block;
  padding-bottom: 0;
  position:relative;
  padding-top: 10px;
  font-size: 16px;
  line-height: 28px;
  ${BreakpointUp.md`    
    font-size: 20px;
    line-height: 38px;
    padding-left: 230px;
  `} 
`

class FadeDrop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
    }
    this.handleFade = this.handleFade.bind(this)
  }
  handleFade() {
    const nextState = !this.state.isVisible
    this.setState({
      isVisible: nextState,
    })
  }

  render() {
    const { question, answer } = this.props
    return (
      <>
        <DropWrapp>
          <DropButton>
            <DropButtonText>{question}</DropButtonText>            
          </DropButton>
          <FadeContent>
            <div dangerouslySetInnerHTML={{__html: answer.childMarkdownRemark.html}} />
          </FadeContent>
        </DropWrapp>
      </>
    )
  }
}
export default FadeDrop